import React from 'react';
import './PlanDetailsSection.css';

const PlanDetailsSection = () => {
    return (
        <section className="plan-details-section">
            <h2 className="section-title">Descubra o Plano Ideal para Sua Clínica</h2>
            <div className="details-grid">
                {/* Exemplo de um detalhe do plano */}
                <div className="detail-card">
                    <h3>Acesso Completo</h3>
                    <p>Todos os planos oferecem acesso completo às funcionalidades essenciais para a gestão eficiente do seu consultório ou clínica.</p>
                </div>
                {/* Detalhes podem ser repetidos para cada funcionalidade ou benefício dos planos */}
                <div className="detail-card">
                    <h3>Prontuários Eletrônicos</h3>
                    <p>Gerencie prontuários com facilidade, garantindo a segurança das informações dos seus pacientes.</p>
                </div>
                <div className="detail-card">
                    <h3>Agendamentos Online</h3>
                    <p>Permita que seus pacientes agendem consultas online, reduzindo o trabalho administrativo.</p>
                </div>
                <div className="detail-card">
                    <h3>Relatórios Detalhados</h3>
                    <p>Obtenha insights valiosos com relatórios detalhados para tomar decisões baseadas em dados.</p>
                </div>
            </div>
        </section>
    );
};

export default PlanDetailsSection;
