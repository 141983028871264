import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import './PlansFAQSection.css';

const PlansFAQSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="plans-faq-section">
            <h2 className="section-title">Perguntas Frequentes</h2>
            <div className="faq-container">
                {[{
                    question: "Qual plano é mais adequado para pequenas clínicas?",
                    answer: "O plano Essencial é ideal para pequenas clínicas, oferecendo recursos fundamentais a um preço acessível."
                }, {
                    question: "Como posso migrar de plano?",
                    answer: "A migração pode ser realizada diretamente através da sua área de cliente, ou entre em contato com nosso suporte para assistência."
                }, {
                    question: "Que tipo de suporte técnico está incluído nos planos?",
                    answer: "Cada plano inclui suporte técnico básico via e-mail e chat, com opções de suporte premium disponíveis para planos avançados incluindo suporte telefônico e resposta prioritária."
                }, {
                    question: "Como o Prontuário Fácil garante a segurança dos dados dos pacientes?",
                    answer: "Utilizamos criptografia de última geração e seguimos rigorosamente as normas de compliance, como a LGPD e HIPAA, para garantir a proteção total dos dados dos pacientes."
                }, {
                    question: "Os planos oferecem integração com outros sistemas?",
                    answer: "Sim, nossos planos oferecem integrações robustas com vários sistemas de gestão hospitalar e ferramentas de terceiros, facilitando um ecossistema de saúde conectado."
                }].map((item, index) => (
                    <div
                        key={index}
                        className={`faq-item ${activeIndex === index ? 'open' : ''}`}
                        onClick={() => toggleFAQ(index)}
                        onKeyPress={(e) => (e.key === 'Enter' || e.key === ' ') && toggleFAQ(index)}
                        tabIndex={0}
                        role="button"
                        aria-expanded={activeIndex === index}
                        aria-controls={`faq-answer-${index}`}
                    >
                        <div className="faq-question">
                            <h3>{item.question}</h3>
                            <span>{activeIndex === index ? <FaMinus /> : <FaPlus />}</span>
                        </div>
                        {activeIndex === index && (
                            <div className="faq-answer" id={`faq-answer-${index}`}>
                                <p>{item.answer}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default PlansFAQSection;
