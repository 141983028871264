import React from "react"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"
import "./PlansTestimonialsSection.css"

const PlansTestimonialsSection = () => {
  return (
    <section className="plans-testimonials-section">
      <h2 className="section-title">O que Nossos Clientes Dizem</h2>
      <div className="testimonials-slider">
        <div className="testimonial-card">
          <FaQuoteLeft className="quote-icon" />
          <p className="testimonial-text">
            "A interface intuitiva do Prontuário Fácil reduziu nosso tempo com
            burocracias e aumentou o tempo com nossos pacientes. Recomendo
            fortemente!"
          </p>
          <p className="testimonial-author">- Dra. Maria Lima, Hospital Dia</p>
          <FaQuoteRight className="quote-icon" />
        </div>
        <div className="testimonial-card">
          <FaQuoteLeft className="quote-icon" />
          <p className="testimonial-text">
            "Impressionado com a capacidade de customização do Prontuário Fácil.
            Adaptou-se perfeitamente às necessidades específicas da nossa
            prática especializada."
          </p>
          <p className="testimonial-author">
            - Dr. João Souza, Centro de Ortopedia Avançada
          </p>
          <FaQuoteRight className="quote-icon" />
        </div>
        <div className="testimonial-card">
          <FaQuoteLeft className="quote-icon" />
          <p className="testimonial-text">
            "Desde que começamos a usar o Prontuário Fácil, notamos uma melhora
            significativa na gestão de dados dos pacientes. O acesso rápido e
            seguro é um diferencial."
          </p>
          <p className="testimonial-author">
            - Dra. Helena Barros, Clínica Dermatológica HB
          </p>
          <FaQuoteRight className="quote-icon" />
        </div>
      </div>
    </section>
  )
}

export default PlansTestimonialsSection
