import React from 'react';
import './planos.css';
import PlansHeroSection from '../components/PlansHeroSection/PlansHeroSection';
import PlanDetailsSection from '../components/PlanDetailsSection/PlanDetailsSection';
import PlansComparisonSection from '../components/PlansComparisonSection/PlansComparisonSection'; // Consider merging if similar
import PlansTestimonialsSection from '../components/PlansTestimonialsSection/PlansTestimonialsSection';
import PlansFAQSection from '../components/PlansFAQSection/PlansFAQSection';
import Seo from '../components/Seo';
import Layout from '../components/layout';
import CallToActionSection from '../components/CallToActionSection/CallToActionSection';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';
import PricingSection from '../components/plantaoFacil/PricingSection';

const Plans = () => {
  const crumbs = [
    { path: '/', label: 'Página Inicial' },
    { path: '/planos', label: 'Planos' }
  ];

  return (
    <Layout>
      <div className="plans-container">
        <Seo
          title="Software Médico: Planos que se adaptam ao seu crescimento"
          description="Encontre o plano ideal para sua clínica ou consultório. Software de gestão de prontuários eletrônicos com agendamento, financeiro e muito mais."
          keywords="prontuário eletrônico, software médico, gestão de clínicas, planos, preços"
        />
        <Breadcrumbs crumbs={crumbs} />
        <main className="content">
          <PlansHeroSection />
          <PricingSection />
          <PlanDetailsSection />
          <PlansComparisonSection />
          <PlansTestimonialsSection />
          <PlansFAQSection />
          <CallToActionSection />
        </main>
      </div>
    </Layout>
  );
};

export default Plans;
