import React from 'react';
import './PlansHeroSection.css';
import { Link } from 'gatsby';

const PlansHeroSection = () => {
    return (
        <section className="plans-hero-section">
            <div className="plans-hero-content">
                <h1 className="plans-hero-title">Planos Desenhados para Impulsionar a Eficiência da Sua Clínica</h1>
                <p className="plans-hero-subtitle">
                    Escolha o plano que melhor se adapta às suas necessidades e comece a transformar a gestão da saúde com tecnologia de ponta.
                </p>
                <Link to="/novo-usuario" id="botao-cadastrar-conta" className="about-hero-cta">Cadastre-se Agora</Link>
            </div>
        </section>
    );
};

export default PlansHeroSection;
