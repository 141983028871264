import React from "react"
import "./PlansComparisonSection.css"

const PlansComparisonSection = () => {
  return (
    <section
      className="plans-comparison-section"
      aria-labelledby="plans-comparison-heading"
    >
      <h2 id="plans-comparison-heading" className="section-title">
        Compare Nossos Planos
      </h2>
      <div className="comparison-table" role="table">
        <div className="table-header" role="rowgroup">
          <div className="header-item" role="columnheader">
            Características
          </div>
          <div className="header-item" role="columnheader">
            Essencial
          </div>
          <div className="header-item" role="columnheader">
            Avançado
          </div>
          <div className="header-item" role="columnheader">
            Profissional
          </div>
          <div className="header-item" role="columnheader">
            Corporativo
          </div>
        </div>
        <div className="table-row" role="row">
          <div className="feature" role="rowheader">
            Prontuário Eletrônico
          </div>
          <div className="plan-feature included" role="cell">
            Incluído
          </div>
          <div className="plan-feature included" role="cell">
            Incluído
          </div>
          <div className="plan-feature included" role="cell">
            Incluído
          </div>
          <div className="plan-feature included" role="cell">
            Incluído
          </div>
        </div>
        <div className="table-row" role="row">
          <div className="feature" role="rowheader">
            Agendamento Inteligente
          </div>
          <div className="plan-feature" role="cell">
            Básico
          </div>
          <div className="plan-feature included" role="cell">
            Incluído
          </div>
          <div className="plan-feature included" role="cell">
            Incluído
          </div>
          <div className="plan-feature included" role="cell">
            Incluído
          </div>
        </div>
        <div className="table-row" role="row">
          <div className="feature" role="rowheader">
            Telemedicina
          </div>
          <div className="plan-feature" role="cell">
            Não Incluído
          </div>
          <div className="plan-feature" role="cell">
            Incluído
          </div>
          <div className="plan-feature included" role="cell">
            Incluído
          </div>
          <div className="plan-feature included" role="cell">
            Incluído
          </div>
        </div>
        <div className="table-row" role="row">
          <div className="feature" role="rowheader">
            Suporte Técnico
          </div>
          <div className="plan-feature" role="cell">
            Email
          </div>
          <div className="plan-feature" role="cell">
            Email + Telefone
          </div>
          <div className="plan-feature included" role="cell">
            24/7 Completo
          </div>
          <div className="plan-feature included" role="cell">
            24/7 Completo
          </div>
        </div>
        <div className="table-row" role="row">
          <div className="feature" role="rowheader">
            Gestão de Recebíveis
          </div>
          <div className="plan-feature" role="cell">
            Não Incluído
          </div>
          <div className="plan-feature" role="cell">
            Incluído
          </div>
          <div className="plan-feature included" role="cell">
            Incluído
          </div>
          <div className="plan-feature included" role="cell">
            Incluído
          </div>
        </div>
      </div>
    </section>
  )
}

export default PlansComparisonSection
